import {
  BLOG_HEADER_TOTAL_RESULTS,
  getCurrentUser,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import { encodeURISlug } from './slug';
import { getLocale } from '../selectors/locale-selectors';
import { getIsCreatedWithResponsiveEditor } from '../selectors/app-settings-selectors';

const DEMO_POSTS_URL =
  'https://static.parastorage.com/unpkg-semver/communities-blog-statics/assets';

let _posts;
export const getDemoPosts = async ({
  httpClient,
  getState,
  page = 1,
  pageSize = 6,
  slugs,
  excludeIds = [],
  aggregatorRequest,
  language,
  featuredOnly,
  categoryId,
  tagId,
}) => {
  const state = getState();
  if (isDemoPostsDisabled(state)) {
    return toResponse([]);
  }

  if (!_posts) {
    const url = `${DEMO_POSTS_URL}/${
      getIsCreatedWithResponsiveEditor(state)
        ? 'demo-posts-x.json'
        : 'demo-posts.json'
    }`;
    const { data } = await httpClient.get(url);
    _posts = data;
  }

  if (featuredOnly || categoryId || tagId) {
    const hasPosts = await getHasRealPosts({ aggregatorRequest, language });
    if (hasPosts) {
      return toResponse([]);
    }
    pageSize = 1;
  }

  if (excludeIds.some((id) => !_posts.find((x) => x.id === id))) {
    return toResponse([]);
  }

  const posts = Array.isArray(slugs)
    ? _posts.filter((x) => slugs.includes(encodeURISlug(x.slug)))
    : _posts.slice((page - 1) * pageSize, page * pageSize);

  const firstPublishedDate = new Date();
  firstPublishedDate.setMinutes(firstPublishedDate.getMinutes() - 1);
  const lastPublishedDate = new Date();
  lastPublishedDate.setHours(firstPublishedDate.getHours() - 24);
  const owner = getCurrentUser(state);

  return toResponse(
    posts.map((post) => {
      post.firstPublishedDate = firstPublishedDate.toISOString();
      post.lastPublishedDate = lastPublishedDate.toISOString();
      post.owner = owner;
      return post;
    }),
    _posts.length,
  );
};

const isDemoPostsDisabled = (state) =>
  getLocale(state) !== 'en' ||
  !isExperimentEnabled(
    state,
    'specs.wixBlog.ProvisionWithNoBlogContentForTemplate',
  );

const toResponse = (posts, total = 0) => ({
  posts,
  headers: { [BLOG_HEADER_TOTAL_RESULTS.toLowerCase()]: total },
});

const getHasRealPosts = async ({ language, aggregatorRequest }) => {
  const { posts } = await aggregatorRequest(
    `/v1/post-list-widget/render-model?${[
      'postLimit=1',
      language && `language=${language}`,
      'fields=posts',
    ]
      .filter(Boolean)
      .join('&')}`,
    { throwOnInvalidJson: true },
  );
  return posts.body.length > 0;
};
