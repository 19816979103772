import {
  createAction,
  buildPaginationRequestParams,
  ENTITY_TYPE_POSTS,
  urijs,
  getCategoryIds,
} from '@wix/communities-blog-client-common';
import { normalizePosts } from '../services/post-utils';
import {
  getLazyPaginationParams,
  getPageSize,
} from '../selectors/pagination-selectors';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';
import { getQueryLocale } from '../selectors/locale-selectors';
import { getTotalResults } from '../services/pagination';
import { getDemoPosts } from '../services/demo-posts';
import getEnvironment from '../services/get-environment';

export const FETCH_FEED_POSTS_REQUEST = 'feedPosts/FETCH_REQUEST';
export const FETCH_FEED_POSTS_SUCCESS = 'feedPosts/FETCH_SUCCESS';
export const FETCH_FEED_POSTS_FAILURE = 'feedPosts/FETCH_FAILURE';

export const fetchFeedPostsRequest = createAction(FETCH_FEED_POSTS_REQUEST);
export const fetchFeedPostsSuccess = createAction(
  FETCH_FEED_POSTS_SUCCESS,
  (payload) => payload,
  (payload, meta) => meta,
);
export const fetchFeedPostsFailure = createAction(FETCH_FEED_POSTS_FAILURE);

const makeRequest = ({
  page,
  pageSize,
  featuredOnly,
  excludeContent,
  fieldsets,
  request,
  lang,
  paginationParams,
}) => {
  const _paginationParams =
    paginationParams || buildPaginationRequestParams(page, pageSize);
  return request(
    urijs('/_api/posts').query({
      ..._paginationParams,
      pinnedFirst: true,
      featuredOnly,
      excludeContent,
      fieldsets,
      ...(lang && { lang }),
    }),
    {
      parseHeaders: true,
    },
  );
};

export default function fetchFeedPosts({
  page = 1,
  pageSize: defaultPageSize,
  entityType = ENTITY_TYPE_POSTS,
  featuredOnly,
  excludeContent,
  section,
  fieldsets,
  preFetch = false,
  useLazyPagination = false,
} = {}) {
  return (dispatch, getState, { request, wixCodeApi, httpClient }) => {
    dispatch(fetchFeedPostsRequest({ entityType, page }));
    const pageSize = getPageSize(getState(), {
      overrideSettingsPageSize: defaultPageSize,
      section,
    });
    const promise = makeRequest({
      page,
      pageSize,
      featuredOnly,
      excludeContent,
      fieldsets,
      request,
      lang: getQueryLocale(getState()),
      paginationParams: useLazyPagination
        ? getLazyPaginationParams({ state: getState(), section, page })
        : undefined,
    });
    return preFetch
      ? promise
      : completeFetchFeedPosts(
          { entityType, page, pageSize },
          promise,
        )(dispatch, getState, { wixCodeApi, httpClient });
  };
}

export const completeFetchFeedPosts = (
  { entityType = ENTITY_TYPE_POSTS, page, pageSize },
  promise,
) => async (dispatch, getState, { wixCodeApi, httpClient }) => {
  try {
    let { body = [], headers = {} } = await promise;
    if (!body.length && getEnvironment(wixCodeApi).isEditorSegment) {
      const fake = await getDemoPosts({ httpClient, getState, page, pageSize });
      body = fake.posts;
      headers = fake.headers;
      promise = Promise.resolve({ body, headers });
    }
    dispatch(
      fetchFeedPostsSuccess(
        normalizePosts({
          state: getState(),
          posts: body,
          blogCategoryIds: getCategoryIds(getState()),
        }),
        {
          page,
          entityType,
          entityCount: getTotalResults(headers),
          pageSize,
        },
      ),
    );
  } catch (_) {
    dispatch(fetchFeedPostsFailure({ entityType, page }));
  }
  return promise;
};

export const fetchFeedPostsPromisified = createPromisifiedAction(
  fetchFeedPosts,
  () => null,
  (response) => response.status,
);
